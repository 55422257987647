import { FluidObject } from "gatsby-image";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { Section } from "../../../Shared/Layout/components/Section/Section";
import { SectionHeaderWithBgImage } from "../../../Shared/SectionHeaderWithBgImage/SectionHeaderWithBgImage";
import { theme } from "../../../Shared/Theme/Theme";
import { ServiceReference } from "../ServiceReference/ServiceReference";
import { ServiceSectionDescriptionAndSendAppointment } from "../ServiceSectionDescriptionAndSendAppointment/ServiceSectionDescriptionAndSendAppointment";
import ReactMarkdown from "react-markdown";

export interface ServiceSectionProps {
  className?: string;
  title: string;
  image: FluidObject;
  content: string;

  reference?: ReactNode;
}

const ServiceDescriptionTitle = styled.h1`
  margin-bottom: 30px;
  font-size: 42px;
`;

const ServiceSectionInner = (props: ServiceSectionProps) => {
  const { title, image, content, reference } = props;
  return (
    <Section className={props.className} id={title}>
      <SectionHeaderWithBgImage image={image} title={title} />
      <ServiceSectionDescriptionAndSendAppointment
        serviceDescription={
          <>
            <ServiceDescriptionTitle>{title}</ServiceDescriptionTitle>
            <ReactMarkdown source={content} />
          </>
        }
        serviceRightPanel={reference}
      />
    </Section>
  );
};

export const ServiceSection = styled(ServiceSectionInner)`
  p {
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
  }

  li {
    color: ${theme.color.gray};
    &::before {
      content: "•";
      font-size: 20px;
      color: ${theme.color.primaryDark};
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
`;
