import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { Section } from "../../../Shared/Layout/components/Section/Section";
import { ServiceReference } from "../ServiceReference/ServiceReference";
import { usePersonImage } from "./hooks/usePersonImage";

export interface ServiceSectionDescriptionAndSendAppointmentProps {
  className?: string;
  serviceDescription: ReactNode;
  serviceRightPanel?: ReactNode;
}

const ServiceSectionDescriptionAndSendAppointmentInner = (
  props: ServiceSectionDescriptionAndSendAppointmentProps
) => {
  const { serviceDescription, serviceRightPanel } = props;
  return (
    <Section className={props.className}>
      <Container>
        <Grid container spacing={4}>
          <Grid item md={7} sm={6} xs={12}>
            {serviceDescription}
          </Grid>
          <Grid item md={5} sm={6} xs={12}>
            {serviceRightPanel}
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export const ServiceSectionDescriptionAndSendAppointment = styled(
  ServiceSectionDescriptionAndSendAppointmentInner
)`
  margin-top: 30px;
`;
