import React from "react";
import styled from "styled-components";
import {
  ButtonAndShortText,
  ButtonAndShortTextProps
} from "../../../Shared/Button/ButtonAndShortText/ButtonAndShortText";
import { muiTheme } from "../../../Shared/Theme/Theme";

export interface SectionLinksProps {
  className?: string;
  links: ButtonAndShortTextProps[];
}

const ButtonsWrapper = styled.div``;

const SectionLinksInner = (props: SectionLinksProps) => {
  const { links } = props;
  return (
    <div className={props.className}>
      {links.map((button: any, index) => (
        <ButtonAndShortText
          key={index}
          buttonTitle={button.buttonTitle}
          buttonUrl={`#${button.buttonTitle}`}
          buttonType="primary"
          shortText={button.shortText}
        />
      ))}
    </div>
  );
};

export const SectionLinks = styled(SectionLinksInner)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${muiTheme.breakpoints.down("sm")} {
    ${ButtonAndShortText} {
      display: flex;
      flex: 1;
    }
  }
`;
