import { Link } from "gatsby";
import GatsbyImage, { FluidObject } from "gatsby-image";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { routes } from "../../../../lib/routes";
import { Button } from "../../../Shared/Button/Primary/Button";

export interface ServiceReferenceProps {
  className?: string;
  name: string;
  reference: string;
  image?: any;
}

const ServiceReferenceInner = (props: ServiceReferenceProps) => {
  const { image, name, reference } = props;
  const { t } = useTranslation("translations");
  console.log('name', name);
  console.log("tada", image);

  return (
    <div className={props.className}>
      {image && (
        <ImageWrapper>
          <GatsbyImage fluid={image.childImageSharp.fluid} />
        </ImageWrapper>
      )}
      <TextWrapper>
        <h4>{name}</h4>
        <p>
          <ReactMarkdown source={reference} />
        </p>
      </TextWrapper>
      <Link to={routes.contact.to}>
        <Button type="secondary">
          {t("therapyPage.references.buttonTitle")}
        </Button>
      </Link>
    </div>
  );
};

const ImageWrapper = styled.div`
  max-width: 120px;
  width: 100%;
  margin: 20px auto;
  border-radius: 50%;
  overflow: hidden;
`;

const TextWrapper = styled.div`
  margin: 20px auto;
  text-align: center;
  width: 80%;
`;
export const ServiceReference = styled(ServiceReferenceInner)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  h4 {
    margin-bottom: 10px;
    font-size: 18px;
  }
`;
