import { useStaticQuery, graphql } from "gatsby";

export const usePersonImage = () => {
  const { file } = useStaticQuery(graphql`
    query PersonImage {
      file(relativePath: { eq: "Services/hromada_foto.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return file.childImageSharp.fluid;
};
