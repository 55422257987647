import Grid from "@material-ui/core/Grid";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { CustomAccordion } from "../../../../../Shared/CustomAccordion/CustomAccordion";

export interface ExpectationDescriptionProps {
  className?: string;
}

const ExpectationDescriptionInner = (props: ExpectationDescriptionProps) => {
  const { t } = useTranslation("translations");
  const markdownSource = t("briefCoachingPage.coachingEnhance.content");

  return (
    <Grid className={props.className} item md={9} sm={7} xs={12}>
      <h3>{t("briefCoachingPage.coachingEnhance.title")}</h3>
      <Description>
        <ReactMarkdown source={markdownSource} />
      </Description>
      <CustomAccordion
        title={"Aký prístup môžete očakávať?"}
        htmlSource={`        
        <p>Ako Váš „Lodivod“ využívam pri práci najmä Brief Coaching a Solution Focused coaching, ktorý podnecuje rozmýšľať o možných riešeniach oveľa viac než o problémoch a prekážkach.  Pracujem  spôsobom, aby ma klient čo najskôr prestal potrebovať. Často nám stačia 1 až 3 stretnutia.
        Neanalyzujem problém ani „ťaživú minulosť“, nerobím diagnostiku. Som zameraný na želanú budúcnosť </p>
        <p>Koučujem hravo a odvážne. Preferujem  autentický prístup k metóde, k výberu slov, k človeku. Toto svoje nastavenie nepoužívam „iba“ v práci, ale žijem ho.</p>
        <p>Držím  sa hesla, že „ najrýchlejšie si spomenieš na to, čo si sám vymyslel.“  Verím, že len vlastným preskúmavaním a objavením „svojich právd“ môže človek získať stabilitu v živote. Bude sa tak môcť v čase, keď to bude potrebovať , oprieť sám o seba.</p>
        <ul>
        <li>Hravý, odvážny, priamo k veci</li>
        <li>100% orientovaný na prax</li>
        <li>Som     nastavený na to, aby ste všetky potrebné kompetencie získali     v čo najkratšom čase a už nepotrebovali moju pomoc a     dokázali si v krízových situáciách poradiť sami.</li>
        <li>Dôverujem     Vám, že máte všetky potrebné zdroje (schopnosti, sily..) k     riešeniu.</li>
        <li>Nevidím     Vás ako „chodiaci problém“ ale ako „chodiace riešenie“.</li>
        <li>Tie     najlepšie riešenia nie sú tie, ktoré sú vytvorené na mieru,     ale tie, ktoré sú vytvorené sebou samým. Vy sám ste expert na     svoj život.</li>
        <li>Neanalyzujem     problém ani minulosť, nerobím diagnostiku. Som zameraný na     želanú budúcnosť.</li>
        <li>Akceptujem     a rešpektujem Vás (váš slovník, svet, priority a hodnoty,     pocity)</li>
        <li>Nemám     potrebu Vás „otáčať“ alebo udávať smer, dávam „len“     IMPULZ.</li>
        <li>Počúvam     Vás tak, aby ste cítili, že ste pre tento svet jedinečný a     dôležitý.</li>
        </ul>
        <p>INTIMITA     - obsah rozhovoru zostáva iba medzi mnou a Vami... ako pri spovedi     :)</p>
`}
      />
    </Grid>
  );
};

export const ExpectationDescription = styled(ExpectationDescriptionInner)``;
const Description = styled.div`
  padding-top: 20px;
  /* //TODO: color of dots  */
  li {
    color: ${(props) => props.theme.color.gray};
    padding-top: 5px;
  }
`;
