import React from "react";
import styled from "styled-components";
import { usePersonImage } from "../shared/ServiceSectionDescriptionAndSendAppointment/hooks/usePersonImage";
import { CoachingServicesSections } from "./CoachingServicesSections/CoachingServicesSections";

export interface CoachingServicesProps {
  className?: string;
}

const CoachingServicesInner = (props: CoachingServicesProps) => {
  const file = usePersonImage();

  return (
    <div className={props.className}>
      <CoachingServicesSections />
    </div>
  );
};

export const CoachingServices = styled(CoachingServicesInner)``;
