import Grid from "@material-ui/core/Grid";
import GatsbyImage from "gatsby-image";
import React from "react";
import styled from "styled-components";
import { useCoachImage } from "../hooks/useCoachImage";

export interface ImageLeftSideProps {
  className?: string;
}

const ImageLeftSideInner = (props: ImageLeftSideProps) => {
  const coachPhoto = useCoachImage();

  return (
    <Grid item md={3} sm={5} xs={12}>
      <ImageWrapper>
        <GatsbyImage fluid={coachPhoto.coach} />
      </ImageWrapper>
    </Grid>
  );
};

const ImageWrapper = styled.div`
  width: 100%;
`;
export const ImageLeftSide = styled(ImageLeftSideInner)``;
