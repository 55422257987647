import { graphql, useStaticQuery } from "gatsby";

export const useCoachingServices = () => {
  const { strapiCoaching: { services } } = useStaticQuery(graphql`
    query useCoachingServices {
      strapiCoaching {
        services {

          title
          content: text
          shortText
          image {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }

          reference {
            name: title
            reference: text
            image {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }

        }
      }
    }
  `);

  return services;
};
