import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { TextSection } from "../../../../Shared/Layout/components/TextSection/TextSection";

export interface BriefConsultationsSectionProps {
  className?: string;
}

const BriefConsultationsSectionInner = (
  props: BriefConsultationsSectionProps
) => {
  const { t } = useTranslation("translations");

  return (
    <TextSection
      title={t(
        "briefCoachingPage.briefConsultationsForSustainableResults.title"
      )}
      description={t(
        "briefCoachingPage.briefConsultationsForSustainableResults.text"
      )}
      className={props.className}
    />
  );
};

export const BriefConsultationsSection = styled(
  BriefConsultationsSectionInner
)``;
