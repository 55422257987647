import { useStaticQuery, graphql } from "gatsby";

export const useCoachImage = () => {
  const { coach } = useStaticQuery(graphql`
    query CoachImage {
      coach: file(
        relativePath: { eq: "shared/Hromada_avatar_briefcoaching.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return {
    coach: coach.childImageSharp.fluid
  };
};
