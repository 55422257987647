import React from "react";
import styled from "styled-components";
import { ServiceReference } from "../../shared/ServiceReference/ServiceReference";
import {
  ServiceSection,
  ServiceSectionProps,
} from "../../shared/ServiceSection/ServiceSection";
import { useCoachingServices } from "./hooks/useTherapyServices";

export interface CoachingServicesSectionsProps {
  className?: string;
}

const CoachingServicesSectionsInner = (
  props: CoachingServicesSectionsProps
) => {
  const services = useCoachingServices();
  

  return (
    <div className={props.className}>
      {services.map((service) => (
        <ServiceSection
          key={service.title}
          title={service.title}
          content={service.content}
          image={service.image.childImageSharp.fluid}
          reference={
            service?.reference?.name && (
              <ServiceReference {...service.reference} />
            )
          }
        />
      ))}
    </div>
  );
};

export const CoachingServicesSections = styled(CoachingServicesSectionsInner)``;
