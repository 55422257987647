import { Container } from "@material-ui/core";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { ButtonAndShortTextProps } from "../../../../Shared/Button/ButtonAndShortText/ButtonAndShortText";
import { Section } from "../../../../Shared/Layout/components/Section/Section";
import { SectionLinks } from "../../../shared/SectionLinks/SectionLinks";

export interface CoachingSectionLinksProps {
  className?: string;
}

const CoachingSectionLinksInner = (props: CoachingSectionLinksProps) => {
  const { t } = useTranslation("translations");
  const links: ButtonAndShortTextProps[] = t(
    "briefCoachingPage.whatCanYouExpect.buttons",
    {
      returnObjects: true
    }
  ).map(link => ({ ...link, buttonTitle: link.button }));

  return (
    <Section className={props.className}>
      <Container>
        <SectionLinks links={links} />
      </Container>
    </Section>
  );
};

export const CoachingSectionLinks = styled(CoachingSectionLinksInner)``;
