import React from "react";
import { BlogSection } from "../../components/Home/BlogSection/BlogSection";
import { CoachingServices } from "../../components/Services/Coaching/CoachingServices";
import { BriefConsultationsSection } from "../../components/Services/Coaching/components/BriefConsultationsSection/BriefConsultationsSection";
import { ButtonsWithShortTextSection } from "../../components/Services/Coaching/components/ButtonsWithShortTextSection/ButtonsWithShortTextSection";
import { CoachingSectionLinks } from "../../components/Services/Coaching/components/CoachingSectionLinks/CoachingSectionLinks";
import { WhatCanYouExpectSection } from "../../components/Services/Coaching/components/WhatCanYouExpectSection/WhatCanYouExpectSection";
import { FormSection } from "../../components/Shared/FormSection/FormSection";
import { TopHeader } from "../../components/Shared/Layout/components/TopHeader/TopHeader";
import Layout from "../../components/Shared/Layout/Layout";

const ServicesCoachingPage = () => (
  <Layout>
    <TopHeader title="Služby / Brief koučing" />
    <BriefConsultationsSection />
    <WhatCanYouExpectSection />
    <CoachingSectionLinks />
    <CoachingServices />
    <BlogSection />
    <FormSection />
  </Layout>
);

export default ServicesCoachingPage;
